
<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Nama Jabatan">
                  <CInput
                    label="Nama Jabatan"
                    placeholder="Nama Jabatan"
                    v-model="form.nama_jabatan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3" v-if="item">
                <CInput label="Kode Jabatan" placeholder readonly v-model="form.kode"></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <validation-provider rules="numeric" v-slot="{ errors }" name="Tunjangan Jabatan">
                  <CInput
                    label="Tunjangan Jabatan"
                    placeholder="Tunjangan Jabatan"
                    v-model="form.tunjangan_jabatan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider rules="numeric" v-slot="{ errors }" name="Angka Kredit">
                  <CInput
                    label="Angka Kredit"
                    placeholder="Angka Kredit"
                    v-model="form.angka_kredit"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider rules="numeric" v-slot="{ errors }" name="Batas Usia">
                  <CInput
                    label="Batas Usia Pensiun"
                    placeholder="Batas Usia Pensiun"
                    v-model="form.bup"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CTextarea v-model="form.ikhtisar_jabatan" label="Iktisar Jabatan" rows="9" />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Swal from "sweetalert2";
export default {
  name: "FormInformasiUmum",
  props: ["item"],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errorMessage: null,
      form: {
        kode: null,
        nama_jabatan: null,
        tunjangan_jabatan: null,
        angka_kredit: null,
        bup: null,
        ikhtisar_jabatan: null,
      },
      loading: false,
    };
  },
  watch: {
    item: function (item) {
      if (item) {
        this.form.kode = item.kode;
        this.form.nama_jabatan = item.nama_jabatan;
        this.form.tunjangan_jabatan = item.tunjangan_jabatan;
        this.form.angka_kredit = item.angka_kredit;
        this.form.bup = item.bup;
        this.form.ikhtisar_jabatan = item.ikhtisar_jabatan;
      }
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("jabatan_umum_informasi/update", {
              data: this.form,
              id: vm.item.id,
            })
          : await this.$store.dispatch("jabatan_umum_informasi/store", this.form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              vm.form.kode = null;
              vm.form.nama_jabatan = null;
              vm.form.tunjangan_jabatan = null;
              vm.form.angka_kredit = null;
              vm.form.bup = null;
              vm.form.ikhtisar_jabatan = null;
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
